import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";

export default function PrintRateUs(props){
    const headers = {
        "ar": ["تم تحديث امتداد تنزيل الصور الخاص بك إلى أحدث إصدار"],
        "am": ["የእርስዎ ኤክስቴንሽን የምስል አውርዶ ወደ ቅንብሩ ተሻሽሏል"],
        "bg": ["Вашето разширение за изтегляне на изображения беше актуализирано до най-новата версия"],
        "bn": ["আপনার এক্সটেনশন ইমেজ ডাউনলোডারটি সর্বশেষ সংস্করণে আপডেট করা হয়েছে"],
        "ca": ["La teva extensió de descarregador d'imatges s'ha actualitzat a l'última versió"],
        "cs": ["Vaše rozšíření pro stahování obrázků bylo aktualizováno na nejnovější verzi"],
        "da": ["Din udvidelse billeddownloader blev opdateret til den nyeste version"],
        "de": ["Ihre Erweiterung Bild-Downloader wurde auf die neueste Version aktualisiert"],
        "el": ["Η επέκταση λήψης εικόνας ενημερώθηκε στην πιο πρόσφατη έκδοση"],
        "en": ["Your extension Image downloader was updated to the latest version"],
        "es": ["Su extensión de descargador de imágenes se actualizó a la última versión"],
        "et": ["Teie pildiallalaadija laiend on värskendatud uusimale versioonile"],
        "fa": ["افزونه دانلود کننده تصویر شما به آخرین نسخه به‌روزرسانی شد"],
        "fi": ["Laajennuksesi kuvien latausohjelma päivitettiin uusimpaan versioon"],
        "fil": ["Ang iyong extension Image downloader ay na-update sa pinakabagong bersyon"],
        "fr": ["Votre extension de téléchargeur d'images a été mise à jour vers la dernière version"],
        "gu": ["તમારું એક્સટેન્શન છબી ડાઉનલોડર નવીનતમ સંસ્કરણમાં અપડેટ થયું"],
        "he": ["ההרחבה שלך להורדת תמונות עודכנה לגרסה האחרונה"],
        "hi": ["आपके एक्सटेंशन इमेज डाउनलोडर को नवीनतम संस्करण में अपडेट किया गया"],
        "hr": ["Vaše proširenje za preuzimanje slika ažurirano je na najnoviju verziju"],
        "hu": ["A képletöltő bővítménye frissült a legújabb verzióra"],
        "id": ["Ekstensi pengunduh gambar Anda diperbarui ke versi terbaru"],
        "it": ["La tua estensione per il download di immagini è stata aggiornata all'ultima versione"],
        "ja": ["拡張機能の画像ダウンローダーが最新バージョンに更新されました"],
        "kn": ["ನಿಮ್ಮ ವಿಸ್ತರಣೆ ಚಿತ್ರ ಡೌನ್‌ಲೋಡರ್ ಅನ್ನು ಇತ್ತೀಚಿನ ಆವೃತ್ತಿಗೆ ನವೀಕರಿಸಲಾಗಿದೆ"],
        "ko": ["귀하의 확장 프로그램 이미지 다운로더가 최신 버전으로 업데이트되었습니다"],
        "lt": ["Jūsų plėtinys vaizdų atsisiuntimo programa atnaujinta į naujausią versiją"],
        "lv": ["Jūsu paplašinājums attēlu lejupielādētājs tika atjaunināts uz jaunāko versiju"],
        "ml": ["നിങ്ങളുടെ എക്സ്റ്റെൻഷൻ ഇമേജ് ഡൗൺലോഡർ ഏറ്റവും പുതിയ പതിപ്പിലേക്ക് അപ്‌ഡേറ്റ് ചെയ്യപ്പെട്ടു"],
        "mr": ["तुमचा विस्तार इमेज डाउनलोडर नवीनतम आवृत्तीत अद्यतनित केला गेला आहे"],
        "ms": ["Sambungan pemuat turun imej anda telah dikemas kini ke versi terkini"],
        "nl": ["Uw extensie afbeeldingsdownloader is bijgewerkt naar de nieuwste versie"],
        "no": ["Utvidelsen din for bildenedlasting ble oppdatert til den nyeste versjonen"],
        "pl": ["Twoje rozszerzenie do pobierania obrazów zostało zaktualizowane do najnowszej wersji"],
        "pt": ["Sua extensão de downloader de imagens foi atualizada para a versão mais recente"],
        "ro": ["Extensia dvs. de descărcare a imaginilor a fost actualizată la cea mai recentă versiune"],
        "ru": ["Ваше расширение для загрузки изображений было обновлено до последней версии"],
        "sk": ["Vaša rozšírenie na sťahovanie obrázkov bolo aktualizované na najnovšiu verziu"],
        "sl": ["Vaša razširitev za prenos slik je bila posodobljena na najnovejšo različico"],
        "sr": ["Ваше проширење за преузимање слика је ажурирано на најновију верзију"],
        "sv": ["Din tilläggsprogram för bildnedladdning har uppdaterats till den senaste versionen"],
        "sw": ["Kiendelezi chako cha kupakua picha kimesasishwa hadi toleo jipya zaidi"],
        "ta": ["உங்கள் நீட்டிப்பு படத்தைப் பதிவிறக்கி சமீபத்திய பதிப்பிற்கு புதுப்பிக்கப்பட்டது"],
        "te": ["మీ విస్తరణ చిత్రం డౌన్‌లోడర్‌ను తాజా వెర్షన్‌కు అప్డేట్ చేయబడింది"],
        "th": ["โปรแกรมเสริมดาวน์โหลดรูปภาพของคุณได้รับการอัปเดตเป็นเวอร์ชันล่าสุด"],
        "tr": ["Uzantınızın görüntü indiricisi en son sürüme güncellendi"],
        "uk": ["Ваше розширення для завантаження зображень було оновлено до останньої версії"],
        "vi": ["Tiện ích mở rộng tải xuống hình ảnh của bạn đã được cập nhật lên phiên bản mới nhất"],
        "zh_CN": ["您的扩展程序图像下载器已更新到最新版本"],
        "zh_TW": ["您的擴充功能圖像下載器已更新到最新版本"],
        "zu": ["Isandiso sakho sokulanda izithombe sibuyekeziwe kuya kunguqulo lwakamuva"]
    };
    
    const text = {
        "ar": { 
            "p1": "نحن نعمل بجد لجعلها أفضل إضافة لتنزيل الصور، إذا كنت سعيدًا بإضافتنا", 
            "p2": "يرجى النظر في", 
            "p3": "تقييم الإضافة.",
            "p4": "ما الجديد",
            "p5": "1) الآن تقوم الإضافة بتنزيل الصور بالتقاط الصور الخلفية.",
            "p6": "2) أضفنا فلترًا متقدمًا. الآن تتحقق الإضافة من أحجام الصور البديلة. يمكنك عرض جميع الأحجام أو بعضها. افتراضيًا، ستقوم الإضافة بمحاولة الحصول على أكبر حجم."
        },
        "am": { 
            "p1": "እኛ እንቀላቀል የምስል አውርዶ ቅንብሩን እንዲሆን እንድለምን፣ እንደ እርስዎ እንደ ተጠቃሚ", 
            "p2": "እባክዎን ያስቡ", 
            "p3": "እንድንጠቀም በመስክራት አድርጎት እንድናስቡ.",
            "p4": "አዲስ ምንድን ነው",
            "p5": "1) አሁን እንደ ገና የምስል አውርዶ የመነሻ ስህተቶችን ይወዳድራል።",
            "p6": "2) አዲስ አካል በተጨማሪ የምስሎችን መጠን ይለዋወጣል። እንደአንድ የታሰረ መጠን እንዲያደርጋል ተስማሚ ነው።"
        },
        "bg": { 
            "p1": "Работим усилено, за да го направим най-доброто разширение за изтегляне на изображения. Ако сте доволни от нашето разширение", 
            "p2": "моля, обмислете", 
            "p3": "да оцените разширението.",
            "p4": "какво ново",
            "p5": "1) Разширението вече улавя изображения на фона.",
            "p6": "2) Добавихме разширен филтър. Разширението за изтегляне на изображения сега проверява алтернативни размери на изображенията. Можете да показвате всички тях или само част от тях. По подразбиране разширението ще се опита да получи най-голямото размер."
        },
        "bn": { 
            "p1": "আমরা এটিকে সেরা চিত্র ডাউনলোড এক্সটেনশন করতে কঠোর পরিশ্রম করি, আপনি যদি আমাদের এক্সটেনশন নিয়ে খুশি হন", 
            "p2": "অনুগ্রহ করে বিবেচনা করুন", 
            "p3": "এক্সটেনশনটি রেটিং করুন।",
            "p4": "কি নতুন",
            "p5": "1) এখন চিত্র ডাউনলোড ব্যাকগ্রাউন্ড চিত্রগুলি ধারণ করে।",
            "p6": "2) আমরা একটি উন্নত ফিল্টার যুক্ত করেছি। চিত্র ডাউনলোড এখন বিকল্প চিত্র আকার পরীক্ষা করে। আপনি সমস্ত আকার বা তাদের কিছু প্রদর্শন করতে পারেন। ডিফল্টভাবে, এক্সটেনশনটি সর্বাধিক আকার পাওয়ার চেষ্টা করবে।"
        },
        "cs": { 
            "p1": "Tvrdě pracujeme na tom, aby to bylo nejlepší rozšíření pro stahování obrázků. Pokud jste s naším rozšířením spokojeni", 
            "p2": "prosím zvažte", 
            "p3": "hodnocení rozšíření.",
            "p4": "co je nového",
            "p5": "1) Rozšíření nyní zachycuje obrázky na pozadí.",
            "p6": "2) Přidali jsme pokročilý filtr. Rozšíření nyní kontroluje alternativní velikosti obrázků. Můžete zobrazit všechny velikosti nebo pouze některé z nich. Ve výchozím nastavení se rozšíření pokusí získat největší velikost."
        },
        "de": { 
            "p1": "Wir arbeiten hart daran, die beste Bild-Downloader-Erweiterung zu machen. Wenn Sie mit unserer Erweiterung zufrieden sind", 
            "p2": "bitte erwägen", 
            "p3": "die Erweiterung zu bewerten.",
            "p4": "was ist neu",
            "p5": "1) Der Bild-Downloader erfasst jetzt Hintergrundbilder.",
            "p6": "2) Wir haben einen erweiterten Filter hinzugefügt. Der Bild-Downloader prüft jetzt alternative Bildgrößen. Sie können alle Größen oder einige von ihnen anzeigen. Standardmäßig versucht die Erweiterung, die größte Größe zu erhalten."
        },
        "el": { 
            "p1": "Δουλεύουμε σκληρά για να το κάνουμε την καλύτερη επέκταση λήψης εικόνας. Εάν είστε ευχαριστημένοι με την επέκτασή μας", 
            "p2": "παρακαλώ σκεφτείτε", 
            "p3": "να αξιολογήσετε την επέκταση.",
            "p4": "τι νέο υπάρχει",
            "p5": "1) Η επέκταση τώρα καταγράφει εικόνες φόντου.",
            "p6": "2) Προσθέσαμε ένα προηγμένο φίλτρο. Η επέκταση τώρα ελέγχει για εναλλακτικά μεγέθη εικόνας. Μπορείτε να εμφανίσετε όλα ή μερικά από αυτά. Από προεπιλογή, η επέκταση θα προσπαθήσει να πάρει το μεγαλύτερο μέγεθος."
        },
        "en": { 
            "p1": "We work hard to make it the best image download extension. If you are happy with our extension", 
            "p2": "please consider", 
            "p3": "rating the extension.",
            "p4": "what's new",
            "p5": "1) Image download now captures background images.",
            "p6": "2) We added an advanced filter. Image downloads now check for alternative image sizes. You can display all of them or some of them. By default, the extension will try to get the largest size."
        },
        "es": { 
            "p1": "Trabajamos duro para que sea la mejor extensión de descarga de imágenes. Si estás contento con nuestra extensión", 
            "p2": "por favor considera", 
            "p3": "calificar la extensión.",
            "p4": "qué hay de nuevo",
            "p5": "1) La descarga de imágenes ahora captura imágenes de fondo.",
            "p6": "2) Hemos añadido un filtro avanzado. La descarga de imágenes ahora verifica tamaños de imagen alternativos. Puedes mostrar todos ellos o algunos de ellos. Por defecto, la extensión intentará obtener el tamaño más grande."
        },
        "fa": { 
            "p1": "ما سخت کار می‌کنیم تا بهترین افزونه دانلود تصویر را بسازیم. اگر از افزونه ما راضی هستید", 
            "p2": "لطفا در نظر داشته باشید", 
            "p3": "افزونه را ارزیابی کنید.",
            "p4": "چه چیزی جدید است",
            "p5": "1) اکنون دانلود تصویر، تصاویر پس‌زمینه را نیز ثبت می‌کند.",
            "p6": "2) ما فیلتر پیشرفته‌ای اضافه کرده‌ایم. دانلود تصاویر اکنون اندازه‌های جایگزین تصویر را بررسی می‌کند. شما می‌توانید همه یا برخی از آنها را نمایش دهید. به طور پیش‌فرض، افزونه تلاش می‌کند بزرگ‌ترین اندازه را دریافت کند."
        },
        "fil": { 
            "p1": "Nagsusumikap kami upang gawin itong pinakamahusay na pag-download ng imahe ng extension. Kung masaya ka sa aming extension", 
            "p2": "mangyaring isaalang-alang", 
            "p3": "na i-rate ang extension.",
            "p4": "ano ang bago",
            "p5": "1) Ngayon ay nahuhuli ng pag-download ng imahe ang mga background na imahe.",
            "p6": "2) Nagdagdag kami ng advanced na filter. Ang pag-download ng imahe ay ngayon ay sumusuri para sa mga alternatibong sukat ng imahe. Maaari mong ipakita ang lahat ng mga ito o ilan sa mga ito. Sa pamamagitan ng default, susubukan ng extension na makuha ang pinakamalaking sukat."
        },
        "fr": { 
            "p1": "Nous travaillons dur pour en faire la meilleure extension de téléchargement d'images. Si vous êtes satisfait de notre extension", 
            "p2": "merci de considérer", 
            "p3": "évaluer l'extension.",
            "p4": "quoi de neuf",
            "p5": "1) Le téléchargement d'images capture désormais les images d'arrière-plan.",
            "p6": "2) Nous avons ajouté un filtre avancé. Le téléchargement d'images vérifie désormais les tailles d'image alternatives. Vous pouvez afficher toutes les tailles ou seulement certaines d'entre elles. Par défaut, l'extension essaiera d'obtenir la taille la plus grande."
        },
        "he": { 
            "p1": "אנחנו עובדים קשה כדי להפוך את זה לתוסף הורדת התמונות הטוב ביותר. אם אתה מרוצה מהתוסף שלנו", 
            "p2": "אנא שקול", 
            "p3": "לדרג את התוסף.",
            "p4": "מה חדש",
            "p5": "1) תוסף ההורדה כעת תופס תמונות רקע.",
            "p6": "2) הוספנו מסנן מתקדם. כעת תוסף ההורדה בודק גדלי תמונה חלופיים. אתה יכול להציג את כולם או כמה מהם. כברירת מחדל, התוסף ינסה להשיג את הגודל הגדול ביותר."
        },
        "hi": { 
            "p1": "हम इसे सबसे अच्छा छवि डाउनलोड एक्सटेंशन बनाने के लिए मेहनत कर रहे हैं। यदि आप हमारे एक्सटेंशन से खुश हैं", 
            "p2": "कृपया विचार करें", 
            "p3": "एक्सटेंशन को रेट करने का।",
            "p4": "नया क्या है",
            "p5": "1) छवि डाउनलोड अब पृष्ठभूमि छवियों को कैप्चर करता है।",
            "p6": "2) हमने एक उन्नत फ़िल्टर जोड़ा है। छवि डाउनलोड अब वैकल्पिक छवि आकार की जाँच करता है। आप उनमें से सभी या उनमें से कुछ प्रदर्शित कर सकते हैं। डिफ़ॉल्ट रूप से, एक्सटेंशन सबसे बड़े आकार को प्राप्त करने का प्रयास करेगा।"
        },
        "hr": { 
            "p1": "Trudimo se da ga učinimo najboljim proširenjem za preuzimanje slika. Ako ste zadovoljni s našim proširenjem", 
            "p2": "molimo razmislite", 
            "p3": "o ocjenjivanju proširenja.",
            "p4": "što je novo",
            "p5": "1) Proširenje sada hvata slike pozadine.",
            "p6": "2) Dodali smo napredni filtar. Preuzimanje slika sada provjerava alternativne veličine slika. Možete prikazati sve njih ili samo neke od njih. Zadano, proširenje će pokušati dobiti najveću veličinu."
        },
        "hu": { 
            "p1": "Keményen dolgozunk azon, hogy a legjobb képlemérő kiterjesztést készítsük. Ha elégedett a kiterjesztésünkkel", 
            "p2": "kérjük, fontolja meg", 
            "p3": "a kiterjesztés értékelését.",
            "p4": "mi újság",
            "p5": "1) A képlemérő mostantól háttérképeket is rögzít.",
            "p6": "2) Fejlett szűrőt adtunk hozzá. A képlemérő most már ellenőrzi az alternatív képméreteket. Megjelenítheti az összeset vagy csak egyeseket. Alapértelmezés szerint a kiterjesztés megpróbálja megszerezni a legnagyobb méretet."
        },
        "id": { 
            "p1": "Kami bekerja keras untuk menjadikannya ekstensi unduhan gambar terbaik. Jika Anda puas dengan ekstensi kami", 
            "p2": "silakan pertimbangkan", 
            "p3": "untuk memberi penilaian pada ekstensi.",
            "p4": "apa yang baru",
            "p5": "1) Unduhan gambar sekarang menangkap gambar latar belakang.",
            "p6": "2) Kami menambahkan filter canggih. Unduhan gambar sekarang memeriksa ukuran gambar alternatif. Anda dapat menampilkan semuanya atau beberapa di antaranya. Secara default, ekstensi akan mencoba mendapatkan ukuran terbesar."
        },
        "it": { 
            "p1": "Lavoriamo sodo per renderlo il miglior componente aggiuntivo per il download delle immagini. Se sei soddisfatto del nostro componente aggiuntivo", 
            "p2": "per favore considera", 
            "p3": "di valutare il componente aggiuntivo.",
            "p4": "cosa c'è di nuovo",
            "p5": "1) Il download delle immagini ora cattura le immagini di sfondo.",
            "p6": "2) Abbiamo aggiunto un filtro avanzato. I download delle immagini ora controllano le dimensioni alternative delle immagini. Puoi visualizzarle tutte o solo alcune di esse. Per impostazione predefinita, l'estensione tenterà di ottenere la dimensione più grande."
        },
        "ja": { 
            "p1": "私たちはそれを最高の画像ダウンロード拡張機能にするために一生懸命働いています。もしあなたが私たちの拡張機能に満足しているのなら", 
            "p2": "ぜひ", 
            "p3": "拡張機能の評価を検討してください。",
            "p4": "新しい機能",
            "p5": "1) 画像ダウンロードは現在、背景画像もキャプチャします。",
            "p6": "2) 高度なフィルターを追加しました。画像ダウンロードは現在、代替画像サイズをチェックします。すべてのサイズまたは一部のサイズを表示できます。デフォルトでは、拡張機能は最大サイズを取得しようとします。"
        },
        "ko": { 
            "p1": "우리는 그것을 최고의 이미지 다운로드 확장 프로그램으로 만들기 위해 열심히 작업하고 있습니다. 만약 당신이 우리의 확장 프로그램에 만족한다면", 
            "p2": "부디", 
            "p3": "확장 프로그램 평가를 고려해 주세요.",
            "p4": "새로운 것",
            "p5": "1) 이미지 다운로드가 이제 배경 이미지를 캡처합니다.",
            "p6": "2) 고급 필터를 추가했습니다. 이미지 다운로드는 이제 대체 이미지 크기를 확인합니다. 모든 크기 또는 일부를 표시할 수 있습니다. 기본적으로 확장 프로그램은 가장 큰 크기를 얻으려고 합니다."
        },
        "mk": { 
            "p1": "Трудиме напорно да го направиме најдоброто проширување за преземање слики. Ако сте задоволни со нашето проширување", 
            "p2": "ве молиме разгледајте", 
            "p3": "да ја оцените проширувањето.",
            "p4": "ново",
            "p5": "1) Проширувањето сега ги снима сликите на позадината.",
            "p6": "2) Додадовме напреден филтер. Преземањето на сликите сега проверува алтернативни големини на сликите. Можете да ги прикажете сите или некои од нив. По природа, проширувањето ќе се обиде да добие најголемата големина."
        },
        "ml": { 
            "p1": "അത് മികച്ച ചിത്ര ഡൗൺലോഡ് എക്സ്റ്റൻഷനാക്കാൻ ഞങ്ങൾ പ്രതിസന്ധിയിലായിരിക്കുന്നു. നിങ്ങൾ ഞങ്ങളുടെ എക്സ്റ്റൻഷനിൽ സന്തോഷത്തോടെ ഇരിക്കുന്നുവെങ്കിൽ", 
            "p2": "ദയവായി പരിഗണിക്കുക", 
            "p3": "എക്സ്റ്റൻഷൻ റേറ്റിംഗ്.",
            "p4": "എന്ത് പുതുവിട്ട്",
            "p5": "1) ഇമേജ് ഡൗൺലോഡ് ഇനി പശ്ചാത്തല ചിത്രങ്ങൾ കൈകാര്യം ചെയ്യുന്നു.",
            "p6": "2) ഞങ്ങൾ ഒരു പുരോഗമിതമായ ഫിൽറ്റർ ചേർത്തു. ഇമേജ് ഡൗൺലോഡ് ഇപ്പോൾ പര്യായ ഇമേജ് വലുപ്പങ്ങൾ പരിശോധിക്കുന്നു. നിങ്ങൾ എല്ലാം അല്ലെങ്കിൽ ചിലത് പ്രദർശിപ്പിക്കാം. ഡിഫോൾട്ട് ആയി, എക്സ്റ്റൻഷൻ ഏറ്റവും വലിയ വലുപ്പം നേടാൻ ശ്രമിക്കും."
        },
        "my": { 
            "p1": "ဓာတ်ပုံများကို ဒေါင်းလုဒ်လုပ်ရန် အကောင်းဆုံး အတိုးအကွဲ ဖြစ်စေရန် ကျွန်ုပ်တို့ အထူးကြိုးစားလျက်ရှိပါသည်။ သင်၏ အထူးအတိုးတွင် ဝမ်းသာပါက", 
            "p2": "ကျေးဇူးပြု၍ စဉ်းစားပါ", 
            "p3": "အထူးအတိုးကို အကဲဖြတ်ပါ။",
            "p4": "အသစ်ဘာလဲ",
            "p5": "1) အခုဆိုရင် အကြောင်းအရာဓာတ်ပုံများကို ဖမ်းယူပါသည်။",
            "p6": "2) အဆင့်မြင့် အစိမ်းတံ့ကို ထည့်သွင်းပါသည်။ အကြောင်းအရာဓာတ်ပုံများသည် အစားထိုး ဓာတ်ပုံ အရွယ်အစားများကို စစ်ဆေးပါသည်။ သင်သည် အားလုံးကို သို့မဟုတ် အချို့ကို ပြသနိုင်သည်။ ကန့်သတ်တန်ဖိုးအနေဖြင့် အထူးအတိုးသည် အကြီးဆုံး အရွယ်အစားကို ရယူရန် ကြိုးစားပါလိမ့်မည်။"
        },
        "ne": { 
            "p1": "हामी यसलाई सबैभन्दा राम्रो छवि डाउनलोड विस्तार बनाउन कडा मेहनत गर्दैछौं। यदि तपाईं हाम्रो विस्तारसँग खुसी हुनुहुन्छ भने", 
            "p2": "कृपया विचार गर्नुहोस्", 
            "p3": "विस्तारलाई रेट गर्न।",
            "p4": "नयाँ के छ",
            "p5": "1) छवि डाउनलोडले अब पृष्ठभूमि छविहरूलाई समेट्छ।",
            "p6": "2) हामीले एक उन्नत फिल्टर थपेका छौं। छवि डाउनलोड अब वैकल्पिक छवि आकारहरूको जाँच गर्छ। तपाईं ती सबै वा केही मात्र देखाउन सक्नुहुन्छ। पूर्वनिर्धारित रूपमा, विस्तारले सबैभन्दा ठूलो आकार प्राप्त गर्न प्रयास गर्नेछ।"
        },
        "no": { 
            "p1": "Vi jobber hardt for å gjøre det til den beste bildeutvidelsen for nedlasting. Hvis du er fornøyd med utvidelsen vår", 
            "p2": "vær så snill å vurdere", 
            "p3": "å gi en vurdering av utvidelsen.",
            "p4": "hva er nytt",
            "p5": "1) Bildeutvidelsen fanger nå bakgrunnsbilder.",
            "p6": "2) Vi har lagt til et avansert filter. Bildeutvidelsen sjekker nå alternative bildestørrelser. Du kan vise alle dem eller noen av dem. Som standard vil utvidelsen prøve å få den største størrelsen."
        },
        "pl": { 
            "p1": "Ciężko pracujemy, aby uczynić to najlepszym rozszerzeniem do pobierania obrazów. Jeśli jesteś zadowolony z naszego rozszerzenia", 
            "p2": "proszę rozważyć", 
            "p3": "ocenienie rozszerzenia.",
            "p4": "co nowego",
            "p5": "1) Rozszerzenie teraz przechwytuje obrazy tła.",
            "p6": "2) Dodaliśmy zaawansowany filtr. Rozszerzenie teraz sprawdza alternatywne rozmiary obrazów. Możesz wyświetlić wszystkie lub niektóre z nich. Domyślnie rozszerzenie spróbuje uzyskać największy rozmiar."
        },
        "pt": { 
            "p1": "Estamos trabalhando duro para torná-lo a melhor extensão de download de imagens. Se você está satisfeito com nossa extensão", 
            "p2": "por favor considere", 
            "p3": "avaliar a extensão.",
            "p4": "o que há de novo",
            "p5": "1) O download de imagens agora captura imagens de fundo.",
            "p6": "2) Adicionamos um filtro avançado. O download de imagens agora verifica tamanhos alternativos de imagens. Você pode exibir todos eles ou alguns deles. Por padrão, a extensão tentará obter o maior tamanho."
        },
        "ro": { 
            "p1": "Lucrăm din greu pentru a-l face cea mai bună extensie pentru descărcarea imaginilor. Dacă ești mulțumit de extensia noastră", 
            "p2": "te rugăm să iei în considerare", 
            "p3": "evaluarea extensiei.",
            "p4": "ce e nou",
            "p5": "1) Extensia acum captează imagini de fundal.",
            "p6": "2) Am adăugat un filtru avansat. Descărcările de imagini acum verifică dimensiunile alternative ale imaginilor. Poți afișa toate dimensiunile sau doar câteva dintre ele. În mod implicit, extensia va încerca să obțină cea mai mare dimensiune."
        },
        "ru": { 
            "p1": "Мы усердно работаем, чтобы сделать это лучшее расширение для загрузки изображений. Если вы довольны нашим расширением", 
            "p2": "пожалуйста, рассмотрите", 
            "p3": "оценку расширения.",
            "p4": "что нового",
            "p5": "1) Расширение теперь захватывает фоновые изображения.",
            "p6": "2) Мы добавили расширенный фильтр. Теперь расширение проверяет альтернативные размеры изображений. Вы можете отображать все из них или только некоторые из них. По умолчанию расширение будет пытаться получить наибольший размер."
        },
        "sr": { 
            "p1": "Trudimo se da ga učinimo najboljim proširenjem za preuzimanje slika. Ako ste zadovoljni s našim proširenjem", 
            "p2": "molimo razmislite", 
            "p3": "o ocenjivanju proširenja.",
            "p4": "što je novo",
            "p5": "1) Proširenje sada hvata slike pozadine.",
            "p6": "2) Dodali smo napredni filter. Preuzimanje slika sada proverava alternativne veličine slika. Možete prikazati sve veličine ili samo neke od njih. Podrazumevano, proširenje će pokušati da dobije najveću veličinu."
        },
        "sv": { 
            "p1": "Vi arbetar hårt för att göra det till det bästa bildnedladdningstillägget. Om du är nöjd med vårt tillägg", 
            "p2": "överväg att", 
            "p3": "betygsätta tillägget.",
            "p4": "vad är nytt",
            "p5": "1) Bildnedladdning fångar nu bakgrundsbilder.",
            "p6": "2) Vi har lagt till ett avancerat filter. Bildnedladdningen kontrollerar nu alternativa bildstorlekar. Du kan visa alla eller några av dem. Som standard kommer tillägget att försöka hämta den största storleken."
        },
        "ta": { 
            "p1": "இது சிறந்த படங்களைப் பதிவிறக்கத் தொகுப்பாக மாற்ற நாம் கடுமையாகப் பணியாள்கிறோம். நீங்கள் எங்கள் தொகுப்பில் திருப்தி அடைந்திருந்தால்", 
            "p2": "தயவுசெய்து", 
            "p3": "தொகுப்பை மதிப்பீடு செய்ய சிந்தியுங்கள்.",
            "p4": "புதியது என்ன",
            "p5": "1) படங்களைப் பதிவிறக்குவது இப்போது பின்னணி படங்களைப் பிடிக்கிறது.",
            "p6": "2) நாங்கள் ஒரு மேம்பட்ட வடிகட்டி சேர்த்தோம். படம் பதிவிறக்கம் இப்போது மாற்று படம் அளவுகளைச் சோதிக்கிறது. நீங்கள் அனைத்தையும் அல்லது சிலவற்றை மட்டும் காணலாம். இயல்புக்கே, தொகுப்பானது மிகப் பெரிய அளவைக் கிடைக்க முயற்சிக்கும்."
        },
        "th": { 
            "p1": "เราทำงานอย่างหนักเพื่อทำให้มันเป็นส่วนขยายการดาวน์โหลดภาพที่ดีที่สุด หากคุณพอใจกับส่วนขยายของเรา", 
            "p2": "โปรดพิจารณา", 
            "p3": "ให้คะแนนส่วนขยาย",
            "p4": "มีอะไรใหม่",
            "p5": "1) การดาวน์โหลดภาพตอนนี้จับภาพภาพพื้นหลังได้แล้ว",
            "p6": "2) เราเพิ่มฟิลเตอร์ขั้นสูง การดาวน์โหลดภาพตอนนี้ตรวจสอบขนาดภาพทางเลือก คุณสามารถแสดงทั้งหมดหรือบางส่วน โดยค่าเริ่มต้น ส่วนขยายจะพยายามดึงขนาดที่ใหญ่ที่สุด"
        },
        "tr": { 
            "p1": "Bunu en iyi resim indirme uzantısı yapmak için çok çalışıyoruz. Uzantımızdan memnunsanız", 
            "p2": "lütfen", 
            "p3": "uzantıyı değerlendirin.",
            "p4": "yeni olan nedir",
            "p5": "1) Resim indirme artık arka plan resimlerini de yakalıyor.",
            "p6": "2) Gelişmiş bir filtre ekledik. Resim indirme artık alternatif resim boyutlarını kontrol ediyor. Tüm boyutları veya sadece bazılarını görüntüleyebilirsiniz. Varsayılan olarak, uzantı en büyük boyutu almaya çalışacaktır."
        },
        "uk": { 
            "p1": "Ми наполегливо працюємо над тим, щоб зробити це найкращим розширенням для завантаження зображень. Якщо ви задоволені нашим розширенням", 
            "p2": "будь ласка, розгляньте", 
            "p3": "оцінювання розширення.",
            "p4": "що нового",
            "p5": "1) Розширення тепер захоплює зображення фону.",
            "p6": "2) Ми додали розширений фільтр. Тепер розширення перевіряє альтернативні розміри зображень. Ви можете відобразити всі з них або лише деякі з них. За замовчуванням розширення намагатиметься отримати найбільший розмір."
        },
        "vi": { 
            "p1": "Chúng tôi đang làm việc chăm chỉ để biến nó thành phần mở rộng tải xuống hình ảnh tốt nhất. Nếu bạn hài lòng với tiện ích mở rộng của chúng tôi", 
            "p2": "xin vui lòng xem xét", 
            "p3": "đánh giá tiện ích mở rộng.",
            "p4": "cái mới",
            "p5": "1) Tiện ích mở rộng tải xuống hình ảnh hiện đã chụp được hình ảnh nền.",
            "p6": "2) Chúng tôi đã thêm một bộ lọc nâng cao. Tiện ích mở rộng tải xuống hình ảnh hiện kiểm tra các kích thước hình ảnh thay thế. Bạn có thể hiển thị tất cả hoặc chỉ một số kích thước. Theo mặc định, tiện ích mở rộng sẽ cố gắng lấy kích thước lớn nhất."
        },
        "zh_CN": { 
                "p1": "我们努力将其打造成最佳的图片下载扩展。如果您对我们的扩展感到满意", 
                "p2": "请考虑", 
                "p3": "对扩展进行评分。",
                "p4": "有什么新变化",
                "p5": "1) 图片下载现在可以捕捉背景图像。",
                "p6": "2) 我们添加了一个高级过滤器。图片下载现在检查替代的图像尺寸。您可以选择显示所有尺寸或其中的一些。默认情况下，扩展将尝试获取最大的尺寸。"
            },
        "zh_TW": { 
            "p1": "我們努力使其成為最佳的圖片下載擴展。如果您對我們的擴展感到滿意", 
            "p2": "請考慮", 
            "p3": "對擴展進行評分。",
            "p4": "有什麼新變化",
            "p5": "1) 圖片下載現在可以捕捉背景圖片。",
            "p6": "2) 我們新增了一個高級過濾器。圖片下載現在會檢查替代的圖片尺寸。您可以選擇顯示所有尺寸或其中的一些。預設情況下，擴展將嘗試獲取最大的尺寸。"
        }
    }
    
            
    const { browser, lang } = useParams();
    let url ="https://chromewebstore.google.com/detail/image-downloader-save-all/fpelahbljekknahkcaegamhcndkfpfnc";
    if(browser=="edge"){
        url ="https://microsoftedge.microsoft.com/addons/detail/image-downloader-save-a/flbolonkboilcbnohjlchlfgdalomdpl"
    }else if(browser=="opera"){
        url="opera"
    }
    let header = headers[lang]; 
    let p1 = text[lang]['p1'];
    let p2 = text[lang]['p2'];
    let p3 = text[lang]['p3'];
    let p4 = text[lang]['p4'];
    let p5 = text[lang]['p5'];
    let p6 = text[lang]['p6'];
    let enLink = "../image_downloader-rate_us/"+browser+"/en";
        return(
        <div  style={{backgroundImage:"URL(/../assets/meercat-download-images.jpeg)", width:"1024px", height:"1024px"}}>
            <NavLink to="/en/contact"><img src="/../assets/email-icon.svg" style={{float:"right", width:"48px", margin:"16px"}} /></NavLink>
            {lang=='en'?"":<NavLink to= {enLink}><img src="/../assets/en.svg" style={{float:"right", width:"44px", margin:"24px 12px"}} /></NavLink>}
            <div style={{color:"white",margin:"48px"}}>
<br/>
<h1 style={{backgroundColor:"rgb(0,0,0,0.6)", padding:"4px"}}>{header}</h1>
<h2>
    <div style={{backgroundColor:"rgb(0,0,0,0.6)", paddingLeft:"8px"}}><p><h2>{p4}</h2>{p5}<br /><br />{p6}</p>{p1} {p2} <a href={url} style={{padding:"4px",color:"orange"}}>{p3}</a></div>
</h2>
</div>
        </div>
        )
}