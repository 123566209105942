export default function Header(props){
    return(
<div className="page-header">
        <svg width="100%" height="16" z-index="-1" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <rect x="0" y="0" width="100%" height="100%" fill="orange"/>
            </svg>
        <span style={{color:"black", position:"relative", top:"-20px"}}>{props.title}</span>
        <br/><br/>
    </div>
    )
}