import { HashLink as Link } from 'react-router-hash-link';
import Limitations from "../Limitations.js";
import PinExtension from "../PinExtension.js";
import SupportUS from "../SupportUs.js";
import Header from '../../Header.js';

export default function ImageDownloader(props){
    document.title = "Image downloader";
    return(
        <>
        <Header title="Image Downloader" x="20"/>
        <div className="article">
        <p><b>Quickly find and download images from web pages in bulk</b></p>
        <p>
You can filter the images based on image characteristics.<br/>
You can select which image to download or download all images that pass filtering.<br/>
You can choose a custom folder for the images and a custom file name sequence.</p>
<p>
<b>Main features:</b>
<br/>
<ul>
    <li>Very quick and clean group display of all images.</li>
    <li>Download a single, multiple or all images in the web page.</li>
    <li>Filter images by: width, height, type, orientation and aspect ratio or URL.</li>
    <li>Custom or auto generated folder and file names.</li></ul></p>
Available for: <a href="https://chromewebstore.google.com/detail/image-downloader-save-all/fpelahbljekknahkcaegamhcndkfpfnc">Chrome</a>,&nbsp;
 <a href="https://microsoftedge.microsoft.com/addons/detail/image-downloader-save-a/flbolonkboilcbnohjlchlfgdalomdpl">Edge</a> and &nbsp;
 <a href="https://addons.mozilla.org/en-US/firefox/addon/image-downloader-for-firefox/?utm_source=addons.mozilla.org&utm_medium=referral&utm_content=search">Firefox</a>.
        </div>
            <Limitations />
            <div className="article">
            Before using the Image Downloader follow the instruction below to make sure that the browser settings won't interfere. 
            </div>
            <div id="check-settings" className="main-title"><p>Check browser settings</p></div>
        <div className="article">
            On <b>Chrome</b> and <b>Edge</b> go to <b>settings -> downloads</b>, make sure that <b>"Ask where to save each file before downloading"</b> is turned <b>off</b>.
            <br/><img src="../assets/checkBrowser.png" style={{marginTop:"24px"}}/><br/>
            On <b>Firefox</b> go to <b>settings -> general -> files and applications</b>, make sure that <b>"Always ask you where to save files"</b> is <b>unchecked</b>.
        </div>
        <PinExtension />
        <div id="tutorial" className="main-title"><p>User guide</p></div>
        <div className="article">
        <p>To use the Image Downloader press the extension icon, this will open the popup window and start scanning the web page for images.
The popup menu is divided into 3 main parts: the main menu, the submenu and the results. </p><img src="../assets/image downloader 1.png" /> <br/>
Use the menu buttons to change the submenu being displayed. Use the save icon <img src="../assets/save-icon.png" /> in the submenu to save 
the settings of that submenu (only of that submenu). 
<p>You can select one or more images by clicking on them in the result part. Notice that large images have this <img src="../assets/new-tab.svg" width="20px" /> "open in a new tab" icon. 
To download your selected images click the download selected 
    images icon <img src="../assets/download-selected.png" />. To download all the images in the result part press the download all
     icon <img src="../assets/download-all.svg" width="20px" />.
    </p> The last icon in the main menu <img src="../assets/hamburger.svg" width="20px" /> opens a drop menu there you will find, among others, a link to this guide.
            <b><h3>Size submenu</h3></b> This submenu is opened by default, use it to filter images by pixel width and height. You can use the <b>S M L A</b> buttons to quickly set the filter settings to show <b>S</b>mall, <b>M</b>edium, <b>L</b>arge, or <b>A</b>ll images.
            Leave the max values empty if you don't want to set an upper limit.
            <b><h3>Type submenu</h3></b> Here you set the file extensions you want to filter.
            <b><h3>Layout submenu</h3></b> You can use this submenu to filter images based on orientation by pressing one of the left buttons, or more specifically by width to height ratio by changing the sign and value in the input box. Leave the ratio empty to show all ratios (or just press the "All" button).
            <b><h3>URL submenu</h3></b>Image downloader lets you filter images based on partial URL matches. You can choose to include or exclude images that their URL matches the terms in this submenu. 
            To disable this filter, leave the URL field empty, you can press the clean icon <img src="../assets/trash.svg" width="20px" /> to delete the URLs.
            You can use multiple terms to match a URL by separating them with a semicolon <b>without space</b>. for example: wikipedia;google;pixabay.
            <b><h3>Advance submenu</h3></b>Some sites have alternative versions of the image they display, usually it is the same image in different sizes. Here you can choose which version to capture.
             By default it is the largest version that passes your other filters. Another option is to get the smallest one, all of them or all that have unique dimensions.
            <b><h3>Save as submenu</h3></b>Here you can set the folders and files names.<p>All folders are saved in your download folder. 
                By default each time you download an image the extension generates an increasing number as a folder name, made from the date and time. 
                To set the folder manually change the radio button to the custom field.
                Leave it empty to save directly to your download folder or set a custom name, you can also set a sub folder.</p> 
        </div>
        <div id="check-settings" className="main-title"><p>Troubleshooting</p></div>
        <div className="article">
            If you encounter an issue, you can always <Link to="/en/contact">contact</Link> us for help.
        </div>
            <SupportUS />
        </>
        )
}{/*ToDo: 
    save as sub menu
    trouble shooting - play with others ; protected images; browser settings
    display submenue
    add description
    add rate us link
    add image
    side panel
    translate    
    */}